import api from '@/js/services/axios'

export default {
  getAll () {
    return new Promise((resolve, reject) => {
      api.get('public/v1/events')
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getByUuid (uuid) {
    return new Promise((resolve, reject) => {
      api.get('public/v1/events/' + uuid)
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getWeeklyByOrganization(organizationId, weekOffset){
    let weekOffsetQuery = ''
    if (weekOffset){
      weekOffsetQuery = '?weekOffset=' + weekOffset
    }
    return new Promise((resolve, reject) => {
      api.get('public/v1/organization/' + organizationId + '/events/find-weekly' + weekOffsetQuery)
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getWeeksAvailable(organizationId){
    return new Promise((resolve, reject) => {
      api.get('public/v1/organization/' + organizationId + '/events/weeks-available')
        .then(response => {
          var data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  _processEvents(events, myClass){
    return events.map(event => {
      
      event.isEventFull = event.display_available_spots !== null 
        && event.display_available_spots !== undefined 
        && event.display_available_spots === 0
        

      if (!myClass){
        return event
      }

      let closeSignupTime = new Date(event.start_time)
      if (myClass.close_signup_before_minutes > 0){
        closeSignupTime.setMinutes(closeSignupTime.getMinutes() - myClass.close_signup_before_minutes)
      } else {
        //In any case to be safe let's close signup 5 minutes after the event start
        closeSignupTime.setMinutes(closeSignupTime.getMinutes() + 5)
      }
      event.signupClosed = closeSignupTime <= new Date()

      return event
    })
  }
}
