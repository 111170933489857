import api from '@/js/services/axios'
import ClassRepository from '../ClassRepository'

export default {
  getAll () {
    return new Promise((resolve, reject) => {
      api.get('private/v1/classes')
        .then(response => {
          var data = response.data
          data.map(myClass => {
            myClass = ClassRepository._processMyClass(myClass)
            return myClass;
          });
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateClass (myClass){
    return new Promise((resolve, reject) => {
      let myClassDto = this._prepareMyClassObject(myClass)
      api.patch('private/v1/classes/' + myClass.id, myClassDto)
        .then(response => {
          var data = ClassRepository._processMyClass(response.data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createClass (myClass){
    return new Promise((resolve, reject) => {
      let myClassDto = this._prepareMyClassObject(myClass)
      api.post('private/v1/classes', myClassDto)
        .then(response => {
          var data = ClassRepository._processMyClass(response.data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteClass (classId){
    return new Promise((resolve, reject) => {
      api.delete('private/v1/classes/' + classId)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAvailableImages() {
    return new Promise((resolve, reject) => {
      api.get('private/v1/s3/images')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareMyClassObject(myClass){
    if (!myClass){
      return null;
    }

    return {
      name: myClass.name,
      cover: myClass.cover,
      description: myClass.description,
      place: myClass.place,
      price: myClass.price,
      price_text: myClass.price_text,
      participant_limit: myClass.participant_limit,
      sequence: myClass.sequence,
      trainer: myClass.trainer,
      color_code: myClass.color_code,
      close_signup_before_minutes: myClass.close_signup_before_minutes,
      duration_minutes: myClass.duration_minutes,
      show_available_spots: myClass.show_available_spots,
    }
  }
}
