<template>
  <div class="as-website">

    <v-container fluid class="hero-section py-14">
      <v-row justify="center" align="center">
        <!-- Left side content -->
        <v-col cols="12" sm="6" md="5" lg="4" class="text-left order-2 order-sm-1">
          <h1 class="headline-text mb-6">
            <span class="text-color-main">{{ $t('pages.home-view.title') }}</span><br>
            <span class="text-color-pallet-2">{{ $t('pages.home-view.title-2') }}</span>
          </h1>

          <!-- Key benefits - no icons, clean list -->
          <div class="benefits-list mb-8">
            <div class="benefit-item">
              <span class="benefit-text">{{ $t('pages.home-view.benefits.benefit1') }}</span>
            </div>
            <div class="benefit-item">
              <span class="benefit-text">{{ $t('pages.home-view.benefits.benefit2') }}</span>
            </div>
            <div class="benefit-item">
              <span class="benefit-text">{{ $t('pages.home-view.benefits.benefit3') }}</span>
            </div>
          </div>

          <!-- CTA Button with centered container -->
          <div class="cta-container">
            <v-btn 
              @click="showSignupDialog('Vēlos pieteikties')" 
              width="250"
              x-large 
              color="var(--color-pallet-2)" 
              dark 
              class="as-primary-btn"
              elevation="6">
              <span class="btn-content">
                {{ $t('pages.home-view.get-started') }}
                <v-icon right class="ml-2">mdi-arrow-right</v-icon>
              </span>
            </v-btn>
          </div>

          <!-- Trust indicators -->
          <div class="trust-indicators mt-6">
            <div class="social-proof mb-2">{{ $t('pages.home-view.hero-social-proof') }}</div>
            <div class="client-logos">
              <v-tooltip v-for="(org, i) in organizations.slice(0,3)" :key="org.identifier" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <router-link 
                    :to="getLocalisedUrl(`/join/${org.identifier}?as_source=asport`)"
                    class="client-logo-link"
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    @click.native="trackClick(org.identifier)"
                  >
                    <v-avatar 
                      size="40" 
                      :class="['client-logo', `ml-${i > 0 ? 0 : 1}`]"
                    >
                      <img :src="org.src_logo_large" :alt="org.name"/>
                    </v-avatar>
                  </router-link>
                </template>
                <span>{{ org.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>

        <!-- Right side demo -->
        <v-col cols="12" sm="6" md="6" lg="5" class="text-center order-1 order-sm-2">
          <div class="demo-container">
            <img class="hero-img hover-lift" src="/img/devices_2.png" alt="ASport Demo"/>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-14 as-section-grey gradient-dark-bottom">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8" class="text-center">
          <img src="@/assets/logo.png" class="logo" alt="ASport"/>
          <h3 class="text-color-grey text-h4 font-weight-bold">{{ $t('pages.home-view.sections.asport-content') }}</h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="py-14">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="2" md="4" class="text-center d-none d-md-block">
          <img src="/img/mobile_3.png" class="mobile-img" alt="devicesView"/>
        </v-col>
        <v-col cols="12" sm="8" md="8" class="text-center py-0 order-sm-first">
          <h2 v-html="$t('pages.home-view.sections.mission-content')"></h2>
          <v-list three-line class="as-list">
            <v-list-item three-line>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-meditation</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item1-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item1-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item1-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-clock-fast</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item2-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item2-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item2-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="var(--color-pallet-2)">mdi-speedometer</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-color-pallet-2">{{ $t('pages.home-view.list.item3-title') }}</v-list-item-title>
                <v-list-item-subtitle class="as-subtitle">{{ $t('pages.home-view.list.item3-content') }}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{ $t('pages.home-view.list.item3-highlight') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" class="text-center pt-5">
          <v-btn 
            @click="showSignupDialog('Uzzināt vairāk')" 
            width="250"
            x-large 
            color="var(--color-pallet-2)" 
            dark 
            class="as-primary-btn"
            elevation="6">
            <span class="btn-content">
              {{ $t('pages.home-view.sections.mission-button') }}
              <v-icon right class="ml-2">mdi-arrow-right</v-icon>
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    
    <v-container fluid class="py-10 as-section-grey gradient-dark-bottom">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.price-title')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" lg="5" class="text-center">
          <p v-html="$t('pages.home-view.sections.price-content')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn 
            @click="showSignupDialog('Uzzināt izmaksas')" 
            width="250"
            x-large 
            color="var(--color-pallet-2)" 
            dark 
            class="as-primary-btn"
            elevation="6">
            <span class="btn-content">
              {{ $t('pages.home-view.sections.price-button') }}
              <v-icon right class="ml-2">mdi-arrow-right</v-icon>
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10">
      <v-row justify="center">
        <v-col cols="12" md="6" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.clients-title')"></h2>
          <p class="mt-2" v-html="$t('pages.home-view.sections.clients-content')"></p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <template v-if="loading">
          <v-col cols="12" class="text-center">
            <loading-in-progress />
          </v-col>
        </template>
        <template v-else>
          <v-col v-for="org in organizations" 
                 :key="org.identifier" 
                 cols="12" sm="5" md="3" lg="3" 
                 class="text-center mb-2"
                 :class="org.identifier">
            <router-link
                target="_blank"
                :to="getLocalisedUrl(`/join/${org.identifier}?as_source=asport`)"
                @click.native="trackClick(org.identifier)">
              <v-avatar size="144px" class="mb-3">
                <img :src="org.src_logo_large" :alt="org.name"/>
              </v-avatar>
              <br/>
              <div class="font-weight-bold" :style="{ color: org.color_text }">{{ org.name }}</div>
            </router-link>
          </v-col>
        </template>
      </v-row>
    </v-container>


    <v-container fluid class="py-10 as-section-grey gradient-dark-bottom">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="text-color-pallet-2" v-html="$t('pages.home-view.sections.where-title')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" lg="5" class="text-center">
          <p v-html="$t('pages.home-view.sections.where-content')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn 
            @click="showSignupDialog('Vēlos pieteikties')" 
            width="250"
            x-large 
            color="var(--color-pallet-2)" 
            dark 
            class="as-primary-btn"
            elevation="6">
            <span class="btn-content">
              {{ $t('pages.home-view.sections.where-button') }}
              <v-icon right class="ml-2">mdi-arrow-right</v-icon>
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-10 as-homepage-main-section">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <!-- This is a image section -->
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="signUpDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid">
          <p class="mb-6">{{ dialogContent }}</p>
          
          <!-- Tabs for user type selection -->
          <v-tabs v-model="selectedTab" class="mb-6" centered>
            <v-tab key="trainer">{{ $t('pages.home-view.contact-form.trainer') }}</v-tab>
            <v-tab key="studio">{{ $t('pages.home-view.contact-form.studio') }}</v-tab>
            <v-tab-item>
                <v-text-field 
                  :label="$t('pages.home-view.contact-form.form.trainer-name')"
                  :rules="[rules.required]" 
                  v-model="form.name"></v-text-field>
              </v-tab-item>
              <v-tab-item>
                <v-text-field 
                  :label="$t('pages.home-view.contact-form.form.studio-name')"
                  :rules="[rules.required]" 
                  v-model="form.name"></v-text-field>
              </v-tab-item>
            </v-tabs>

          <v-text-field
            v-model="form.phone"
            :label="$t('pages.home-view.contact-form.form.phone')"
            :rules="[rules.required]"
          ></v-text-field>

          <v-text-field
            v-model="form.email"
            :label="$t('pages.home-view.contact-form.form.email')"
            :rules="[rules.required, rules.email]"
          ></v-text-field>

          <v-textarea
            v-model="form.message"
            :label="$t('pages.home-view.contact-form.form.message')"
          ></v-textarea>
        </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn 
            text 
            @click="signUpDialog = false">
            {{ $t('common.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary"
            :loading="loading"
            @click="sendContactForm">
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import slack from '@/js/services/slack'
import urlUtil from '@/js/utils/urlUtil'
import validationRules from '@/js/utils/validation-rules'
import OrganizationRepository from '@/js/api/OrganizationRepository'
import LoadingInProgress from '@/components/public/LoadingInProgress'

export default {
  name: 'HomeView',
  components: {
    LoadingInProgress
  },
  data: () => ({
    signUpDialog: false,
    dialogType: null,
    rules: validationRules,
    loading: false,
    organizations: [],
    dialogTitle: '',
    dialogContent: '',
    selectedTab: null,
    valid: true,
    form: {
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  }),
  metaInfo () {
    return {
      title: this.$t('pages.home-view.seo-title') || '' ,
      meta: [
        { name: 'title', content: this.$t('pages.home-view.seo-title') || '' },
        { name: 'description', content: this.$t('pages.home-view.seo-description') || '' },
        { name: 'url', content: window.location.href || '' },
        { property: 'og:title', content: this.$t('pages.home-view.seo-title') || '' },
        { property: 'og:description', content: this.$t('pages.home-view.seo-description') || '' },
      ]
    }
  },
  computed: {
    landingUrl: function() {
      return this.$store.getters['session/landingUrl']
    },
    referrerUrl: function() {
      return this.$store.getters['session/referrerUrl']
    }
  },
  created: async function() {
    this.$mixpanel.track('ViewHomePage', {
        landingUrl: this.landingUrl,
        referrerUrl: this.referrerUrl
    });
    await this.loadShowcaseOrganizations();
  },
  methods: {
    ...urlUtil,
    sendContactForm: async function() {
      this.loading = true
      const inputForm = this.$refs.form
      const isValid = await inputForm.validate();
      this.error = null
      if (!isValid) {
        this.loading = false
        return
      }

      try {
        // Existing Mixpanel tracking
        this.$mixpanel.identify(this.contactEmail);
        this.$mixpanel.track('HomePageSubmitForm', {
          distinct_id: this.contactEmail,
          button: this.contactButton,
          landingUrl: this.landingUrl,
          referrerUrl: this.referrerUrl
        });

        // Google Analytics 4 event
        this.$gtag.config({
          'user_id': this.form.email
        });
        
        this.$gtag.event('submit_contact_form', {
          'form_type': this.dialogType,
          'button_text': this.contactButton
        });

        // Google Ads Conversion tracking
        /*this.$gtag.event('conversion', {
          'send_to': 'AW-XXXXXXXXXX/YYYYYYYYYYYYYYYYYYY',  // Your Google Ads conversion ID and label
          'value': 1.0,
          'currency': 'EUR',
          'transaction_id': new Date().getTime().toString(),
          'user_data': {
            'email': this.form.email,
            'phone_number': this.form.phone,
          }
        });
        */
       
        // Existing Slack message
        await slack.sendMessage("Contact form to join ASport => Name:" + this.form.name + " Phone:" + this.form.phone + " Email:" + this.form.email + " Details:" + this.form.message + " Button:" + this.contactButton);

        // Success handling
        this.loading = false
        this.form.name = ''
        this.form.phone = ''
        this.form.email = ''
        this.form.message = ''
        this.contactButton = null
        this.$toast.success(this.$t('pages.home-view.contact-form.success-message'))
        this.signUpDialog = false
        this.$refs.form.reset()

      } catch (error) {
        this.loading = false
        console.error(error)
        this.$toast.error(this.$t('pages.home-view.contact-form.submit-error'))
      }
    },
    showSignupDialog: function(actionText) {
      this.signUpDialog = true
      this.contactButton = actionText
      this.$mixpanel.track('HomePageClickButton', {
        button: actionText,
        landingUrl: this.landingUrl,
        referrerUrl: this.referrerUrl
      });
      if (actionText === 'Uzzināt vairāk'){
        this.dialogType = 'features'
        this.dialogTitle = this.$t('pages.home-view.contact-form.feature-title')
        this.dialogContent = this.$t('pages.home-view.contact-form.feature-content')
      } else if (actionText === 'Uzzināt izmaksas'){
        this.dialogType = 'price'
        this.dialogTitle = this.$t('pages.home-view.contact-form.price-title')
        this.dialogContent = this.$t('pages.home-view.contact-form.price-content')
      } else if (actionText === 'Vēlos pieteikties'){
        this.dialogType = 'signup'
        this.dialogTitle = this.$t('pages.home-view.contact-form.title')
        this.dialogContent = this.$t('pages.home-view.contact-form.content')
      }
    },
    trackClick: function(organization) {
      this.$mixpanel.track('HomePageClickOrganization', {
          organization: organization,
          landingUrl: this.landingUrl,
          referrerUrl: this.referrerUrl
      });
    },
    getImageUrl: function(identifier) {
      let logoUrl = '/img'
      const CDN_URL = process.env.VUE_APP_CDN_URL
      if (CDN_URL){
        logoUrl = CDN_URL;
      }
      return logoUrl + '/organization/' + identifier + '/logo_144.jpg'
    },
    async loadShowcaseOrganizations() {
      this.loading = true;
      try {
        const organizations = await OrganizationRepository.findShowcase();
        this.organizations = organizations;
      } catch (error) {
        console.error('Failed to load showcase organizations:', error);
        this.$toast.error(this.$t('common.error.load-failed'));
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
<style scoped>
.no-webp .as-homepage-main-section {
  background-image: url('~@/assets/asport_women_with_phone.png') !important;
}
.webp .as-homepage-main-section {
  background-image: url('~@/assets/asport_women_with_phone.png') !important;
}
.as-homepage-main-section {
  color: #eee;
  background-size: cover;
  background-position: center;
  height: 250px;
}
.as-list {
  text-align: left;
}
.as-list .as-subtitle {
  display: block;
}
img.hero-img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  filter: drop-shadow(0 20px 40px rgba(0,0,0,0.15));
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
}
img.logo {
  max-height: 80px;
  height: 100%;
  width: auto;
}
img.mobile-img {
  max-width: 220px;
  width: 100%;
  height: auto;
}
.gradient-dark-bottom {
  background: linear-gradient(180deg, rgba(204,204,204,0) 0%, rgba(204,204,204,0.5) 100%);
}
.hero-section {
  background: linear-gradient(135deg, var(--color-primary-light) 0%, var(--color-primary) 100%);
  min-height: 80vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.headline-text {
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 1.2;
}

.benefits-list {
  margin: 2rem 0;
}

.benefit-item {
  display: flex;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.benefit-text {
  line-height: 1.4;
}

.trust-indicators {
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 1rem;
}

.social-proof {
  font-size: 0.9rem;
  opacity: 0.9;
}

.client-logos {
  display: flex;
  align-items: center;
}

.client-logo-link {
  text-decoration: none;
  transition: transform 0.2s ease;
}

.client-logo-link:hover {
  transform: translateY(-2px);
}

.client-logo {
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-logo-link:not(:first-child) {
  margin-left: -12px;
}

.client-logo-link:hover {
  z-index: 2;
}

.demo-container {
  position: relative;
  padding: 20px;
}

.hover-lift:hover {
  transform: translateY(-16px);
  filter: drop-shadow(0 30px 60px rgba(0,0,0,0.2));
}

.as-primary-btn {
  text-transform: none;
  border-radius: 28px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 56px;
  font-size: 1.1rem;
}

.as-primary-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0,0,0,0.2);
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .as-primary-btn {
    height: 48px;
    font-size: 1rem;
  }
}

.cta-container {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 600px) {
  .cta-container {
    justify-content: center;
  }
}
</style>
