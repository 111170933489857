<template>
  <div class="text-center pa-5">
    <v-progress-circular
      :size="40"
      :width="5"
      :class="{ 'as-organization-style': isOrganizationPath }"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import urlUtil from '@/js/utils/urlUtil'

export default {
  computed: {
    isOrganizationPath() {
      return urlUtil.isOrganizationPath(this.$route.path)
    }
  }
}
</script>
